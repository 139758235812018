// require('./bootstrap');

$(function() {
	/*** Force reloading a page when using browser back ***/
	window.onpageshow = function(event) {
		if (event.persisted) {
			window.location.reload();
		}
	};

	setiCheck();

	setSelect2();
    setSelect2Clear();

    $(document).on('select2:open', () => {  // BUG FIX on auto focus
        document.querySelector('.select2-container--open .select2-search__field').focus();
    });

    setTooltip();

	setPopOver();
});

/*** iCheck ***/
window.setiCheck = function setiCheck() {
    $('input.iCheck').iCheck({
        checkboxClass: 'icheckbox_flat-blue',
        radioClass: 'iradio_flat-blue'
    });
}

/*** SELECT2 ***/
window.setSelect2 = function setSelect2() {
    $('.form-control.select2').select2({
        language: 'it',
        theme:    'bootstrap4',
        width:    '100%'
    });
}
window.setSelect2Clear = function setSelect2Clear() {
    $('.form-control.select2Clear').select2({
        language: 'it',
        theme:    'bootstrap4',
        width:    '100%',
        placeholder: 'Seleziona...',
        allowClear: true,
    });
}

window.formatItemCustomer = function formatItemCustomer (item) {
    // console.log(item);
    if (item.loading) return item.text;

    var regEx = new RegExp("(" + query_term + ")(?!([^<]+)?>)", "gi");
    var surname = item.surname.replace(regEx, "<span class='select2-rendered__match'>$1</span>");
    var name = item.name.replace(regEx, "<span class='select2-rendered__match'>$1</span>");

    var markup = "<div class='select2-result-repository clearfix' " + (item.disabled ? "style='position: relative'" : "") + ">" +
    "<div class='select2-result-repository__meta'>" +
        "<div class='select2-result-repository__title'>" + surname + ' ' + name + "</div>" +
        "<div class='select2-result-repository__details'>";

    if(item.phone) {
        markup += "<span class='mx-1'><i class='fa fa-phone text-primary'></i> " + item.phone + '</span>';
    }

    if(item.email) {
        markup += "<span class='mx-1'><i class='fa fa-envelope text-primary'></i> " + item.email + '</span>';
    }

    if(item.fiscal_code) {
        markup += "<span class='mx-1'><i class='fa fa-id-card text-primary'></i> " + item.fiscal_code + '</span>';
    }

    markup += "</div>" +
    "</div></div>";

    return markup;
}
window.formatItemCustomerSelection = function formatItemCustomerSelection (item) {
    // console.log(item);
    if(item.id == '') return item.text;

    var markup = "<div class='select2-result-repository clearfix' " + (item.disabled ? "style='position: relative'" : "") + ">" +
    "<div class='select2-result-repository__meta'>" +
        "<div class='select2-result-repository__title'>" + item.surname + ' ' + item.name + 
            (typeof item.url != 'undefined' && item.url != '' ? " <a href='" + item.url + "' target='_blank'><i class='far fa-external-link user-link-icon'></i></a>" : '') + 
        "</div>" +
        "<div class='select2-result-repository__details'>";
    markup += "</div>" +
    "</div></div>";

    return markup;
}

window.formatItemCity = function formatItemCity (item) {
    // console.log(item);
    if (item.loading) return item.text;

    var regEx = new RegExp("(" + query_term + ")(?!([^<]+)?>)", "gi");
    var name = item.name.replace(regEx, "<span class='select2-rendered__match'>$1</span>");
    var code = item.code.replace(regEx, "<span class='select2-rendered__match'>$1</span>");

    var markup = "<div class='select2-result-repository clearfix' " + (item.disabled ? "style='position: relative'" : "") + ">" +
    "<div class='select2-result-repository__meta'>" +
        "<div class='select2-result-repository__title'>" + name + ' (' + code + ')</div>';
    markup += "</div></div></div>";

    return markup;
}
window.formatItemCitySelection = function formatItemCitySelection (item) {
    // console.log(item);
    if(item.id == '') return item.text;

    var markup = "<div class='select2-result-repository clearfix' " + (item.disabled ? "style='position: relative'" : "") + ">" +
                    "<div class='select2-result-repository__meta'>" +
                        "<div class='select2-result-repository__title'>" + item.name + ' (' + item.code + ')</div>';
    markup += "</div></div></div>";

    return markup;
}

/*** NUMBER FORMAT ***/
window.getFormattedNumber = function getFormattedNumber(value) {
    // return value.toFixed(2).replace('.',',');

    value = value.toFixed(2) + '';
    var x = value.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? ',' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + '.' + '$2');
    }
    return x1 + x2;
}

/*** GET TAXABLE AMOUNT ***/
window.getTaxable = function getTaxable(value, tax_rate) {
    return value / ((tax_rate / 100) + 1);
}

/*** GET IMPORT WITH TAX ***/
window.getImportWithTax = function getImportWithTax(value, tax_rate) {
    return value * ((tax_rate / 100) + 1);
}

/*** TOOLTIP ***/
function setTooltip() {
    $('[data-toggle="tooltip"]').tooltip({ delay: { show: 500 } });
}

/*** POPOVER ***/
function setPopOver() {
    $('.help[data-toggle="popover"]').popover({
        // container: 'body',
        html:      true
    });
}

/*** POPOVER ITEM ***/
window.setPopoverItem = function setPopoverItem(url) {
    var hoverTimeout = null;

    $('.popover-item[data-toggle="popover"]').unbind('mouseenter mouseleave').hover(function() {
        var _this = $(this);
        var url = _this.data('url');

        if(!$(".popover:hover").length) {
            $(".popover-item").popover("dispose");
        }

        hoverTimeout = setTimeout(function() {
            $.ajax({
                url: url,
                type: 'GET',
                dataType: 'html',
                success: function(content) {
                    // console.log(content)
                    
                    var popover = _this.popover({  
                        container: 'body',
                        placement: 'auto',
                        // trigger: 'hover',
                        trigger: 'manual',
                        html: true,
                        customClass: 'popover-item-container',
                        // title: 'test',
                        content: content
                    }).on("mouseout", function() {
                        // console.log($(".popover:hover").length)
                        
                        setTimeout(function() {
                            if(!$(".popover:hover").length)
                                $(".popover-item").popover("dispose");
                        }, 300);
                    });

                    popover.popover('show');

                    popover.on('shown.bs.popover', function() {
                        $('.popover-body').on("mouseleave", function() {
                            $(".popover-item").popover("dispose");
                        });
                    });
                }
            });
        }, 200);
    }, function() {
        clearTimeout(hoverTimeout);
    });
}

/*** FIELDSET COLLAPSE ***/
window.setFieldsetCollapse = function setFieldsetCollapse() {
    $('.fieldset-collapse-button').click(function(e) {
        e.preventDefault();

        var target = $($(this).data('target'));

        if(target.hasClass('show')) {
            $(this).html('<i class="fas fa-plus"></i>');
            target.removeClass('show');
        } else {
            $(this).html('<i class="fas fa-minus"></i>');
            target.addClass('show');
        }
    });
}
